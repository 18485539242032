import { render, staticRenderFns } from "./CopyRights.vue?vue&type=template&id=73cd2c2e&scoped=true"
import script from "./CopyRights.vue?vue&type=script&lang=js"
export * from "./CopyRights.vue?vue&type=script&lang=js"
import style0 from "./CopyRights.vue?vue&type=style&index=0&id=73cd2c2e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73cd2c2e",
  null
  
)

export default component.exports