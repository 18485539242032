import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        theme: 'default',
        settings: null,
        client: null,
        user: {},
        currency: 'EUR',
        homeLink: '/',
        isPartnerLogged: false
    },
    mutations: {
        setTheme (state, newTheme) {
            state.theme = newTheme
        },
        setSettings(state, data) {
            state.settings = data.settings
            state.client = data.client
            state.currency = data.settings.currency
        },
        setLoggedUser(state, data) {
            state.isPartnerLogged = true
            state.user = data
        },
        setPartnerLogout(state) {
            state.isPartnerLogged = false
            state.user = {}
        },
        setHeaderLink(state, data) {
            state.homeLink = `/groupBooking?checkIn=${data.checkIn}&checkOut=${data.checkOut}&rooms=1&adults=1&children=0&page=1&groupId=${data.groupId}`
        }
    },
    getters: {
        appSettings(state) {
            return state.settings
        }
    }
})
