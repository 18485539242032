<template>
    <div class="container-fluid copyright py-3 ">
        <div class="row ">
            <div class="container ">
                <div class="row ">
                    <div class="col-12 col-sm order-1 order-sm-0 mt-3 mt-sm-0">
                        <p class="">© {{ new Date().getFullYear() }}
                            <a href="https://www.snowoffice.at" target="_blank">Snowoffice GmbH</a>
                            {{ $t('allRightsReserved') }}
                        </p>
                    </div>
                    <div class="col-12 col-sm-auto order-0 order-sm-1">
                        <ul class="languageUl">
                            <li><a @click="setLanguage('en')" class="cursor changeLanguage">EN</a></li>
                            <li><a @click="setLanguage('de')" class="cursor changeLanguage">DE</a></li>
                            <li><a @click="setLanguage('es')" class="cursor changeLanguage">ES</a></li>
                            <li><a @click="setLanguage('hr')" class="cursor changeLanguage">HR</a></li>
                            <li><a @click="setLanguage('it')" class="cursor changeLanguage">IT</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <button :class="(showAccessibility) ? '' : 'show'" class="accessibility-btn"
                @click="showAccessibility = !showAccessibility">
            <b-icon icon="person-bounding-box"></b-icon>
        </button>

        <div v-if="showAccessibility" @click="showAccessibility=false" class="accessibility-panel-overlay"></div>

        <div :class="(showAccessibility) ? 'show' : ''" class="accessibility-panel shadow-lg rounded-lg overflow-hidden"
             role="dialog"
             aria-labelledby="accessibility-title" aria-hidden="true">

            <div class="h-100 overflow-hidden d-flex flex-column">
                <div
                    class="accessibility-panel-header w-100 border-bottom d-flex justify-content-between align-items-center">
                    <div class="flex-grow-1 p-2">
                        <b>{{ $t('accessibilityTools') }}</b>
                    </div>
                    <div class="p-2 d-flex">
                        <a class="mr-1" @click="resetAll()">
                            <b-icon icon="arrow-counterclockwise"></b-icon>
                        </a>
                        <a @click="showAccessibility = !showAccessibility">
                            <b-icon icon="chevron-down"></b-icon>
                        </a>
                    </div>
                </div>
                <div class="w-100 flex-grow-1 overflow-auto accessibility-action-panel">
                    <div class="col-12 pt-3 mb-3">
                        <div class="form-row">
                            <div
                                v-for="(toolItem, index) in toolItems" :key="'accessibility-item'+index"
                                class="col-6 col-sm-6 mb-2">
                                <div
                                    :class="(toolItem.isSelected === 'TRUE') ? 'selected' : ''"
                                    @click="onChangeMode(toolItem.mode, toolItem.isSelected)"
                                    class="accessibility-modes text-center py-4 h-100 d-flex px-2 flex-column justify-content-center align-items-center">
                                    <b-icon font-scale="1.5" :icon="toolItem.icon"></b-icon>
                                    <div class="w-100 py-1"></div>
                                    <span>{{ toolItem.title }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-sm-flex justify-content-between align-items-center mb-2 md-sm-3">
                        <div class="flex-grow-1 pr-3">
                            <div class="w-100"><small>{{ $t('fontSize') }}</small> <small>({{ form.fontSize }}%)</small></div>
                            <input v-model="form.fontSize"
                                   :style="fontSizeStyle" @input="updatePageStyle()"
                                   ref="fontSlider" value="100" type="range" :min="10" :max="200"/>
                        </div>
                        <div class="py-3 py-sm-0">
                            <button class="incDecButton" @click="decSize('FONT')">
                                <b-icon icon="dash"/>
                            </button>
                            <button class="incDecButton" @click="incSize('FONT')">
                                <b-icon icon="plus"/>
                            </button>
                            <button class="incDecButton" @click="resetSize('FONT')">
                                <b-icon icon="arrow-counterclockwise"/>
                            </button>
                        </div>
                    </div>


                    <div class="col-12 d-sm-flex justify-content-between align-items-center mb-2 md-sm-3">
                        <div class="flex-grow-1 pr-3">
                            <div class="w-100"><small>{{ $t('lineHeight') }}</small> <small>({{ form.lineHeight }}%)</small></div>
                            <input v-model="form.lineHeight"
                                   :style="lineHeightStyle" @input="updatePageStyle()"
                                   ref="fontSlider" value="100" type="range" :min="10" :max="200"/>
                        </div>
                        <div class="py-3 py-sm-0">
                            <button class="incDecButton" @click="decSize('LINE_HEIGHT')">
                                <b-icon icon="dash"/>
                            </button>
                            <button class="incDecButton" @click="incSize('LINE_HEIGHT')">
                                <b-icon icon="plus"/>
                            </button>
                            <button class="incDecButton" @click="resetSize('LINE_HEIGHT')">
                                <b-icon icon="arrow-counterclockwise"/>
                            </button>
                        </div>
                    </div>

                    <div class="col-12 d-sm-flex justify-content-between align-items-center mb-4">
                        <div class="flex-grow-1 pr-3">
                            <div class="w-100"><small>{{ $t('letterSpacing') }}</small> <small>({{ form.letterSpacing }}%)</small>
                            </div>
                            <input v-model="form.letterSpacing"
                                   :style="letterSpacingStyle" @input="updatePageStyle()"
                                   ref="fontSlider" value="100" type="range" :min="10" :max="200"/>
                        </div>
                        <div class="py-3 py-sm-0">
                            <button class="incDecButton" @click="decSize('LETTER_SPACING')">
                                <b-icon icon="dash"/>
                            </button>
                            <button class="incDecButton" @click="incSize('LETTER_SPACING')">
                                <b-icon icon="plus"/>
                            </button>
                            <button class="incDecButton" @click="resetSize('LETTER_SPACING')">
                                <b-icon icon="arrow-counterclockwise"/>
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'CopyRights',
    data() {
        return {
            showAccessibility: false,
            form: {
                fontSize: 100,
                lineHeight: 100,
                letterSpacing: 100,
                lightMode: 'FALSE',
                darkMode: 'FALSE',
                highlightLinks: 'FALSE',
                highLightHeading: 'FALSE',
                screenReader: 'FALSE',
                readingMode: 'FALSE',
            },
            baseFontSize: 16,
            baselineHeight: 1.5,
            baseLetterSpacing: 0.05
        }
    },
    computed: {
        primaryColor() {
            return getComputedStyle(document.body).getPropertyValue('--primary-color').trim()
        },
        toolItems() {
            return [
                {title: this.$t('lightContrast'), mode: 'LIGHT', icon: 'lightbulb', isSelected: this.form.lightMode},
                {title: this.$t('darkContrast'), mode: 'DARK', icon: 'lightbulb-fill', isSelected: this.form.darkMode},
                {title: this.$t('highlightLinks'), mode: 'LINKS', icon: 'link45deg', isSelected: this.form.highlightLinks},
                {title: this.$t('highlightHeadings'), mode: 'HEADING', icon: 'type-h1', isSelected: this.form.highLightHeading},
                {title: this.$t('screenReader'), mode: 'SCREEN', icon: 'binoculars', isSelected: this.form.screenReader},
                {title: this.$t('readingMode'), mode: 'READING', icon: 'megaphone', isSelected: this.form.readingMode},
            ]
        },
        fontSizeStyle() {
            const percentage = ((parseInt(this.form.fontSize) - 10) / (200 - 10)) * 100;
            return {
                background: `linear-gradient(to right, ${this.primaryColor} ${percentage}%, #ddd ${percentage}%)`
            };
        },
        lineHeightStyle() {
            const percentage = ((parseInt(this.form.lineHeight) - 10) / (200 - 10)) * 100;
            return {
                background: `linear-gradient(to right, ${this.primaryColor} ${percentage}%, #ddd ${percentage}%)`
            };
        },
        letterSpacingStyle() {
            const percentage = ((parseInt(this.form.letterSpacing) - 10) / (200 - 10)) * 100;
            return {
                background: `linear-gradient(to right, ${this.primaryColor} ${percentage}%, #ddd ${percentage}%)`
            };
        }
    },
    mounted() {
        const themeMode = localStorage.getItem("themeMode") || ''
        // this.form.lightMode = (themeMode === 'LIGHT') ? 'TRUE' : 'FALSE'
        this.form.lightMode = 'FALSE'
        // this.form.darkMode = (themeMode === 'DARK') ? 'TRUE' : 'FALSE'
        this.form.darkMode = 'FALSE'
        this.form.fontSize = localStorage.getItem("fontSize") || 100
        this.form.lineHeight = localStorage.getItem("lineHeight") || 100
        this.form.letterSpacing = localStorage.getItem("letterSpacing") || 100
        this.form.highlightLinks = localStorage.getItem("highlightLinks") || 'FALSE'
        this.form.highLightHeading = localStorage.getItem("highLightHeading") || 'FALSE'
        this.form.screenReader = localStorage.getItem("screenReader") || 'FALSE'
        this.form.readingMode = localStorage.getItem("readingMode") || 'FALSE'
        this.updatePageStyle()
        document.addEventListener("click", this.readElementText)
    },
    methods: {
        readElementText(event) {
            if (this.form.screenReader === "TRUE") {
                const text = event.target?.innerText?.trim();
                if (text) {
                    speechSynthesis.cancel();
                    const utterance = new SpeechSynthesisUtterance(text);
                    speechSynthesis.speak(utterance);
                }
            } else {
                speechSynthesis.cancel();
            }
        },
        setLanguage(language) {
            localStorage.setItem('HosEngineFrontLanguage', language)
            this.$i18n.locale = language
            this.$moment.locale(this.$i18n.locale)
        },
        resetAll() {
            this.form.lineHeight = 100
            this.form.fontSize = 100
            this.form.letterSpacing = 100
            this.updatePageStyle()
        },
        resetSize(type) {
            if (type === 'FONT') {
                this.form.fontSize = 100
            } else if (type === 'LINE_HEIGHT') {
                this.form.lineHeight = 100
            } else {
                this.form.letterSpacing = 100
            }
            this.updatePageStyle()
        },
        incSize(type) {
            if (type === 'FONT') {
                const oldValue = parseInt(this.form.fontSize)
                let roundedValue = Math.floor(oldValue / 10) * 10;
                if (roundedValue >= 200) {
                    roundedValue = 190
                }
                this.form.fontSize = roundedValue + 10
            } else if (type === 'LINE_HEIGHT') {
                const oldValue = parseInt(this.form.lineHeight)
                let roundedValue = Math.floor(oldValue / 10) * 10;
                if (roundedValue >= 200) {
                    roundedValue = 190
                }
                this.form.lineHeight = roundedValue + 10
            } else {
                const oldValue = parseInt(this.form.letterSpacing)
                let roundedValue = Math.floor(oldValue / 10) * 10;
                if (roundedValue >= 200) {
                    roundedValue = 190
                }
                this.form.letterSpacing = roundedValue + 10
            }
            this.updatePageStyle()
        },
        decSize(type) {
            if (type === 'FONT') {
                const oldValue = parseInt(this.form.fontSize)
                let roundedValue = Math.floor(oldValue / 10) * 10;
                if (roundedValue <= 20) {
                    roundedValue = 20
                }
                this.form.fontSize = roundedValue - 10
            } else if (type === 'LINE_HEIGHT') {
                const oldValue = parseInt(this.form.lineHeight)
                let roundedValue = Math.floor(oldValue / 10) * 10;
                if (roundedValue <= 20) {
                    roundedValue = 20
                }
                this.form.lineHeight = roundedValue - 10
            } else {
                const oldValue = parseInt(this.form.letterSpacing)
                let roundedValue = Math.floor(oldValue / 10) * 10;
                if (roundedValue <= 20) {
                    roundedValue = 20
                }
                this.form.letterSpacing = roundedValue - 10
            }
            this.updatePageStyle()
        },
        updatePageStyle() {

            const fontPercentage = parseInt(this.form.fontSize)
            const lineHeightPercentage = parseInt(this.form.lineHeight)
            const letterSpacingPercentage = parseInt(this.form.letterSpacing)
            let root = document.documentElement

            const newFontSize = (this.baseFontSize * fontPercentage) / 100
            root.style.setProperty('--base-font-size', `${newFontSize}px`); //
            const newLineHeight = (this.baselineHeight * lineHeightPercentage) / 100
            root.style.setProperty('--base-line-height', `${newLineHeight}`); //
            const newLetterSpacing = (this.baseLetterSpacing * letterSpacingPercentage) / 100
            root.style.setProperty('--base-letter-spacing', `${newLetterSpacing}em`); //

            localStorage.setItem("fontSize", this.form.fontSize)
            localStorage.setItem("lineHeight", this.form.lineHeight)
            localStorage.setItem("letterSpacing", this.form.letterSpacing)

            if (this.form.highLightHeading === 'TRUE') {
                document.body.classList.add('highlight-heading')
            } else {
                document.body.classList.remove('highlight-heading');
            }

            if (this.form.highlightLinks === 'TRUE') {
                document.body.classList.add('highlight-links')
            } else {
                document.body.classList.remove('highlight-links');
            }
        },
        onChangeMode(mode, currentValue) {
            const newValue = (currentValue === 'TRUE') ? 'FALSE' : 'TRUE'
            if (mode === 'HEADING') {
                localStorage.setItem("highLightHeading", newValue)
                this.form.highLightHeading = newValue
            } else if (mode === 'LINKS') {
                localStorage.setItem("highlightLinks", newValue)
                this.form.highlightLinks = newValue
            } else if (mode === 'SCREEN') {
                localStorage.setItem("screenReader", newValue)
                this.form.screenReader = newValue
            } else if (mode === 'READING') {
                localStorage.setItem("readingMode", newValue)
                this.form.readingMode = newValue
                this.toggleReadingMode()
            } else {
                const baseClass = this.$store.state.theme
                this.form.lightMode = 'FALSE'
                this.form.darkMode = 'FALSE'
                if (mode === 'LIGHT' && newValue === 'TRUE') {
                    localStorage.setItem("themeMode", "LIGHT")
                    this.form.lightMode = 'TRUE'
                    document.body.className = 'default'
                } else if (mode === 'DARK' && newValue === 'TRUE') {
                    localStorage.setItem("themeMode", "DARK")
                    this.form.darkMode = 'TRUE'
                    document.body.className = 'theme-black'
                } else {
                    localStorage.setItem("themeMode", "")
                    this.form.darkMode = 'FALSE'
                    this.form.lightMode = 'FALSE'
                    document.body.className = baseClass
                }
            }
            this.updatePageStyle()
        },
         toggleReadingMode() {
            // let viewport = document.querySelector("meta[name=viewport]");
            //  console.log(viewport)
            // if (viewport.getAttribute("content") === "width=device-width, initial-scale=1") {
            //     viewport.setAttribute("content", "width=375, user-scalable=no"); // Simulating mobile width (375px)
            // } else {
            //     viewport.setAttribute("content", "width=device-width, initial-scale=1"); // Reset to normal
            // }
            //  document.body.classList.toggle("reading-mode");
        }
    }
}
</script>

<style scoped lang="scss">
.accessibility-btn {
    letter-spacing: 0.05em !important;
    position: fixed;
    display: none;

    &.show {
        display: block;
    }

    bottom: 20px;
    left: 20px;
    width: 60px;
    height: 60px;
    line-height: 65px !important;
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
    z-index: 1050;
    transition: background-color 0.3s ease, transform 0.2s ease-in-out;

    .bi {
        font-size: 25px !important;
        line-height: inherit !important;
        letter-spacing: inherit !important;
        color: var(--text-on-primary);
    }

    &:hover {
        background-color: var(--text-on-primary);
        transform: scale(1.1);

        .bi {
            transform: scale(1.1);
            color: var(--primary-color);
        }
    }
}

.accessibility-panel-overlay {
    background: rgba(0, 0, 0, 0.2);
    z-index: 1001;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.accessibility-panel {
    letter-spacing: inherit !important;
    line-height: 1.5 !important;
    font-size: 16px !important;
    position: fixed;
    display: none;
    bottom: 0;
    left: 5px;
    width: 90%;
    max-width: 350px;
    background: #FFFFFF !important;
    color: var(--text-inverse-color);
    z-index: 1002 !important;

    .accessibility-action-panel {
        max-height: 80vh;
    }

    > * {
        letter-spacing: 1.5px !important;
        font-size: 15px !important;
    }

    &.show {
        display: block;
    }

    .accessibility-panel-header {
        a {
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px !important;
            text-align: center;
            border-radius: 50%;
            font-size: 20px !important;
            letter-spacing: inherit !important;
            cursor: pointer;
            color: var(--black-color) !important;

            &:hover {
                background: var(--primary-light-bg-color);
            }
        }
    }

    .accessibility-modes {
        border: 1px solid var(--primary-light-bg-color);
        border-radius: 10px;
        cursor: pointer;
        //color: var(--primary-color);
        color: var(--black-color);
        transition: all 0.3s;
        line-height: inherit !important;
        letter-spacing: inherit !important;

        .bi {
            color: var(--primary-color);
            line-height: inherit !important;
            letter-spacing: inherit !important;
        }

        span {
            font-size: 14px !important;
            line-height: inherit !important;
            letter-spacing: inherit !important;
        }

        small {
            font-size: 14px !important;
            line-height: inherit !important;
            letter-spacing: 0.05em !important;
        }

        &:hover, &.selected {
            background: var(--primary-color);
            color: var(--text-on-primary);

            .bi {
                color: var(--text-on-primary);
            }
        }
    }

    .incDecButton {
        margin-left: 5px;
        border: 1px solid transparent;
        border-radius: 5px;
        background: var(--primary-light-bg-color);
        color: var(--text-color);
        transition: all 0.3s;

        &:hover {
            background: var(--primary-color);

            .b-icon.bi {
                color: #FFFFFF !important;
            }
        }
    }
}
</style>
