<template>
<div class="w-100 clearfix">
  <div class="container-fluid py-5" id="downloadApp">
    <div class="row">
      <div class="container">
        <div class="row apps py-sm-5">
          <div class="col-12 col-md-5 text-center text-sm-right pt-4 pt-sm-0 order-2 order-sm-1">
            <img src="../../assets/images/download-hosbooking-app.png" width="280" alt="Self Check in app for android and ios" />
          </div>
          <div class="col-12 col-md-6 order-1 order-sm-2">
            <h2 class="font-weight-bold pb-3">The best Self Check-in App<br> for Android & IOS</h2>
            <p class="mb-5">
                {{ $t('appFooterText') }}
              <a class="text-blue mb-4" href="https://www.hosbooking.com/apps" target="_blank">
                    {{ $t('knowMore') }}...
                </a>
            </p>
            <a href="https://play.google.com/store/apps/details?id=com.hosbooking.guest" target="_blank" class="mr-3">
              <img src="../../assets/images/download-on-play-store.png" width="133" />
            </a>
            <a href="https://apps.apple.com/app/hos-guest/id6444342670" target="_blank" class="mr-3">
              <img src="../../assets/images/download-on-app-store.png" width="133" />
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="container-fluid footer py-5">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-4 mt-sm-0 mt-4">
            <h4>{{$t('usefulLinks')}}</h4>
            <ul>
              <li>
                <router-link to="/check">
                  <b-icon-chevron-double-right scale="0.8" /> {{$t('checkBooking')}}
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <b-icon-chevron-double-right scale="0.8" /> {{$t('contact')}}
                </router-link>
              </li>

              <li v-if="$store.state.settings.impressumLink !== ''">
                <a :href="$store.state.settings.impressumLink" target="_blank">
                  <b-icon-chevron-double-right scale="0.8" /> {{$t('impressum')}}
                </a>
              </li>

              <li v-if="$store.state.settings.agbLink !== ''">
                <a :href="$store.state.settings.agbLink" target="_blank">
                  <b-icon-chevron-double-right scale="0.8" /> {{$t('termsAndAgb')}}
                </a>
              </li>
              <li v-if="$store.state.isPartnerLogged">
                <router-link to="/profile">
                  <b-icon-person scale="0.8" /> {{$t('profile.menuProfile')}}
                </router-link>
              </li>
              <li v-else>
                <router-link to="/customerLogin">
                  <b-icon-chevron-double-right scale="0.8" /> {{$t('partnerLogin')}}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-4 mt-sm-0 mt-4">
            <h4>{{$t('reachUs')}}</h4>
            <ul>
              <li>
                <router-link to="/">
                  <b-icon-geo-alt-fill scale="0.9" />
                  <strong>{{$store.state.client.company.name}}</strong>
                  <div class="w-100"></div>
                  {{$store.state.client.company.street}}
                  <div class="w-100"></div>
                  {{$store.state.client.company.postalCode}}
                  {{$store.state.client.company.city}}
                </router-link>
              </li>
              <li>
                <a :href="'tel:'+$store.state.settings.contactNumber">
                  <b-icon-telephone-fill scale="0.9" />
                  {{$store.state.settings.contactNumber}}
                </a>
              </li>
              <li>
                <a :href="'mailto:'+$store.state.settings.reservationMail">
                  <b-icon-envelope-fill scale="0.9" />
                  {{$store.state.settings.reservationMail}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>

</style>
