<template>
    <fixed-header :threshold="80">
        <div class="container-fluid pageHeader shadow-sm">
            <div class="row">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-auto py-2 py-sm-3">
                            <router-link :to="$store.state.homeLink" class="brandLogo">
                                <img :src="$store.state.settings.logo" alt="hosbooking.net"/>
                            </router-link>
                        </div>
                        <div class="col my-auto py-2">
                            <ul class="mainMenu">
                                <li>
                                    <a :href="'tel:'+$store.state.settings.contactNumber"
                                       class="text-dark rounded-pill p-2 p-sm-2">
                                        <b-icon icon="telephone" scale="1.4" class="mx-1 mr-sm-2"></b-icon>
                                        <span
                                            class="d-none d-lg-inline">{{ $store.state.settings.contactNumber }}</span>
                                    </a>
                                </li>
                                <li>
                                    <a :href="'mailto:'+$store.state.settings.reservationMail+'?subject=Hello'"
                                       class="text-dark rounded-pill p-2 p-sm-2">
                                        <b-icon icon="envelope" scale="1.4" class="mx-1 mr-sm-2"></b-icon>
                                        <span class="d-none d-lg-inline">{{$store.state.settings.reservationMail}}</span>
                                    </a>
                                </li>
                                <li>
                                    <router-link to="/check" class="btn btn-primary rounded-pill p-2 p-sm-2">
                                        {{ $t('myBooking') }}
                                        <b-icon icon="arrow-right-circle-fill" scale="0.8" class="d-none d-sm-inline"></b-icon>
                                    </router-link>
                                </li>
                                <li class="d-none d-sm-inline">
                                    <b-dropdown variant="transparent" menu-class="overflow-hidden p-0" toggle-class="rounded-pill p-0" right no-caret>
                                        <template #button-content>
                                            <img :src="getFlag" width="40"/>
                                        </template>
                                        <b-dropdown-item @click="setLanguage('en')" class="changeLanguage">
                                            <img src="../../assets/flag/english.png"/> English
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setLanguage('de')" class="changeLanguage">
                                            <img src="../../assets/flag/deutsch.png"/> Deutsch
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setLanguage('es')" class="changeLanguage">
                                            <img src="../../assets/flag/espanol.png"/> Español
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setLanguage('hr')" class="changeLanguage">
                                            <img src="../../assets/flag/hrvatski.png"/> Hrvatski
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="setLanguage('it')" class="changeLanguage">
                                            <img src="../../assets/flag/italy.png"/> Italiano
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row d-none mb-2">
                        <div class="col-12">
                            <ul class="mainMenu">
                                <li class="">
                                    <router-link to="/check" class="btn btn-blue p-3">
                                        {{ $t('myBooking') }}
                                        <b-icon icon="arrow-right-circle-fill" scale="0.8" class=""></b-icon>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </fixed-header>
</template>

<script>
import FixedHeader from 'vue-fixed-header'
import enFlag from '../../assets/flag/english.png';
import deFlag from '../../assets/flag/deutsch.png';
import esFlag from '../../assets/flag/espanol.png';
import hrFlag from '../../assets/flag/hrvatski.png';
import itFlag from '../../assets/flag/italy.png';
export default {
    name: 'MainHeader',
    components: {
        FixedHeader
    },
    computed: {
        getFlag() {
            const local = this.$i18n.locale
            if (local === 'de') {
                return deFlag
            } else if (local === 'es') {
                return esFlag
            } else if (local === 'hr') {
                return hrFlag
            } else if (local === 'it') {
                return itFlag
            } else {
                return enFlag
            }
        }
    },
    methods: {
        setLanguage(language) {
            localStorage.setItem('HosEngineFrontLanguage', language)
            this.$i18n.locale = language
            this.$moment.locale(this.$i18n.locale)
        }
    }
}
</script>

<style  lang="scss">
.brandLogo img {
    height: 50px;
    margin: 0;
    display: block;
}

.pageHeader.vue-fixed-header--isFixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 1000;
}

.pageHeader {
    z-index: 1;
    position: relative;
    background: var(--header-background);
    //box-shadow: 0 2px 5px var(--primary-light-bg-color);
    .mainMenu {
        display: block;
        float: right;
        padding: 0;
        margin: 0;
        list-style: none;
        > li {
            display: block;
            float: left;
            padding-right: 12px;
            @media (max-width: 768px) {
                padding-right: 8px;
            }
            > a:not(.btn) {
                display: block;
                font-size: 0.9rem;
                background: var(--primary-inverse-color) !important;
                border: 1px solid var(--primary-inverse-border-color);
                color: var(--primary-color) !important;
                transition: all 0.1s;
                &:hover {
                    background: var(--primary-color) !important;
                    color: var(--text-on-primary) !important;
                }
            }
            ul {
                li {
                    float: none;
                    padding-right: 0;
                }
            }

            @media (max-width: 768px) {
                a {
                    display: block;
                }
            }

        }
    }
}
</style>


