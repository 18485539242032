<template>
    <div :class="(cookieAccepted) ? 'd-none' : ''" class="cookiePopUp">
        <p>
            Cookies helfen uns, unsere Dienstleistungen zu erbringen. <br>
            Durch die Nutzung unserer Dienste stimmen Sie der Verwendung von Cookies zu.
            <router-link target="_blank" class="text-white text-underline"
               to="/impressum">Informationen
            </router-link>
        </p>
        <a class="btn btn-primary" @click="acceptCookie()">
            Ich stimme zu
        </a>
    </div>
</template>
<script>
export default {
    name: 'CookiePopUp',
    mounted () {
        this.checkCookie()
    },
    data() {
        return {
            cookieAccepted: true
        }
    },
    methods: {
        acceptCookie () {
            const date = this.$moment().add(30, 'days')
            document.cookie = 'HosCookieAccepted=1; expires=' + date
            this.cookieAccepted = true
        },
        checkCookie () {
            this.cookieAccepted = this.getCookie('HosCookieAccepted') !== null;
        },
        getCookie (name) {
            var dc = document.cookie
            var prefix = name + '='
            var begin = dc.indexOf('; ' + prefix)
            if (begin === -1) {
                begin = dc.indexOf(prefix)
                if (begin !== 0) return null
            } else {
                begin += 2
                var end = document.cookie.indexOf(';', begin)
                if (end === -1) {
                    end = dc.length
                }
            }
            return decodeURI(dc.substring(begin + prefix.length, end))
        }
    }
}
</script>

<style scoped lang="scss">
.cookiePopUp {
    position: fixed;
    width: 90%;
    max-width: 450px;
    padding: 15px;
    bottom: 10px;
    left: 50%;
    border-radius: 20px;
    transform: translateX(-50%);
    box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.85);
    color: #FFF;
    background: var(--black-color);
    z-index: 1100;
}

</style>
