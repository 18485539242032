<template>
  <div>
    <top-header />
    <main-header />
    <router-view />
    <app-footer />
    <copy-rights />
    <cookie-pop-up />
  </div>
</template>

<script>
import TopHeader from '@/components/Template/TopHeader'
import MainHeader from '@/components/Template/MainHeader'
import CookiePopUp from '@/components/Template/CookiePopUp'
import CopyRights from '@/components/Template/CopyRights'
import AppFooter from '@/components/Template/AppFooter'
export default {
  name: 'MainPage',
  components: { AppFooter, CopyRights, CookiePopUp, MainHeader, TopHeader },
}
</script>

<style scoped>

</style>
